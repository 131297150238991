/**
 * @license
 * MyFonts Webfont Build ID 4318538, 2022-01-31T01:05:08-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: SofiaPro-Regular by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular/
 * 
 * Webfont: SofiaPro-Medium by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium/
 * 
 * 
 * Webfonts copyright: Copyright � Olivier Gourvat - Mostardesign Type Foundry, 2020. All rights reserved.
 * 
 * © 2022 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/41e54a");
  
@font-face {
  font-family: "SofiaPro-Regular";
  src: url('src/assets/fonts/webFonts/SofiaProRegular/font.woff2') format('woff2'), url('src/assets/fonts/webFonts/SofiaProRegular/font.woff') format('woff');
}
@font-face {
  font-family: "SofiaPro-Medium";
  src: url('src/assets/fonts/webFonts/SofiaProMedium/font.woff2') format('woff2'), url('src/assets/fonts/webFonts/SofiaProMedium/font.woff') format('woff');
}

