@import '~@angular/material/theming';
@import './custom-material-theme';

@mixin theme-property($property: null, $palette: null, $key: null) {
  & {
    #{$property}: mat-color(map-get($theme, $palette), $key);
  }
  .theme-alternate & {
    #{$property}: mat-color(map-get($theme, $palette), $key);
  }
}

$dc-color-primary: #1d2e3c;
$dc-color-primary-variant: #0074FF;
$dc-color-accent: #e6e7e8;
$dc-color-accent-variant: #c3cfe0;
$dc-color-disabled: #8B8E94;
$dc-color-disabled-variant: #8B8E94;

$custom-typography: mat-typography-config(
  $font-family: 'SofiaPro-Regular, SofiaPro-Medium'
);

@include mat-base-typography($custom-typography);
@include mat-checkbox-typography($custom-typography);
@include angular-material-typography($custom-typography);
@include mat-core($custom-typography);

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
// Default variable overrides

// Required
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// theme
:root {

  .dc-primary {
    color: $dc-color-primary !important;
  }

  .dc-primary-variant {
    color: $dc-color-primary-variant !important;
  }

  .dc-accent {
    color: $dc-color-accent !important;
  }

  .dc-primary-bg {
    background-color: $dc-color-primary !important;
  }

  .dc-accent-bg {
    background-color: $dc-color-accent !important;
  }

  .dc-accent-variant-bg {
    background-color: $dc-color-accent-variant !important;
  }

  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button,
  .mat-button-disabled {
    border-radius: 50px;
  }

  .mat-snack-bar-container {
    color: $dark-text;
    background-color: white;
    border-radius: 0;
    padding: 0;
    margin: 50px;
  }


  --bs-color-primary: #1d2e3c;
  --bs-01-color-primary: #1D2E3C;
  --bs-02-color-primary: #1D2E3C;

  --bs-color-basic: #fff;
  --bs-01-color-basic: #fff;
  --bs-02-color-basic: #fff;

  --bs-color-darkest: #000;
  --bs-color-dark: #343a40;

  --bs-01-color-darkest: #000;
  --bs-01-color-dark: #343a40;

  --bs-02-color-darkest: #000;
  --bs-02-color-dark: #343a40;

  --bs-color-medium: #f0f0f0;
  --bs-01-color-medium: #f0f0f0;
  --bs-02-color-medium: #f0f0f0;

  // Text
  --bs-text-color-primary: #fff;
  --bs-01-text-color-primary: #fff;

  --bs-text-color-inverted-primary: #000;
  --bs-01-text-color-inverted-primary: #000;

  --bs-02-text-color-primary: #fff;
  --bs-02-text-color-inverted-primary: #000;

  --bs-text-size-tiny: 0.8em;
  --bs-text-size-title: 1.5em;
  --bs-text-size-subtitle: 1.2em;

  --bs-01-text-size-small: 1em;
  --bs-01-text-size-tiny: 0.8em;
  --bs-01-text-size-title: 1.5em;
  --bs-01-text-size-subtitle: 1.2em;

  --bs-02-text-size-small: 1em;
  --bs-02-text-size-tiny: 0.8em;
  --bs-02-text-size-title: 1.5em;
  --bs-02-text-size-subtitle: 1.2em;

  --bs-text-weight-sidemenu: 500;
  --bs-text-weight-title: 500;

  --bs-01-text-weight-title: 500;
  --bs-01-text-weight-subtitle: 400;
  --bs-01-text-weight-normal: 200;
  --bs-01-text-weight-bold: 600;
  --bs-01-text-weight-heavy: 450;

  --bs-02-text-weight-title: 500;
  --bs-02-text-weight-subtitle: 400;
  --bs-02-text-weight-normal: 200;
  --bs-02-text-weight-bold: 600;
  --bs-02-text-weight-heavy: 450;

  --bs-text-size-small: 0.8em;
  --bs-text-size-normal: 1em;

  --bs-01-text-size-normal: 1em;
  --bs-02-text-size-normal: 1em;

  --bs-01-text-size-small: 0.8em;
  --bs-02-text-size-small: 0.8em;

  --bs-01-text-size-panel-subtitle: 1em;
  --bs-02-text-size-panel-subtitle: 1em;

  --bs-01-text-size-panel-title: 1.2em;
  --bs-02-text-size-panel-title: 1.2em;

  --bs-01-text-size-amount: 1.5em;
  --bs-02-text-size-amount: 1.5em;

  // Background
  --bs-background-color-default: #ffffff;
  --bs-01-background-color-default: #ffffff;
  --bs-02-background-color-default: #ffffff;

  --bs-background-color-light: #c0c0c0;
  --bs-01-background-color-light: #c0c0c0;
  --bs-02-background-color-light: #c0c0c0;

  --bs-background-color-dark: #6c757d;
  --bs-01-background-color-dark: #363b39;
  --bs-02-background-color-dark: #6c757d;

  --bs-background-color-menu:#979797;
  --bs-01-background-color-menu:#979797;
  --bs-02-background-color-menu:#979797;

  //  Border
  --bs-border-radius: 0.5em;
  --bs-01-border-radius: 0.5em;
  --bs-02-border-radius: 0.5em;

  --bs-border-color-dark: #c0c0c0;
  --bs-border-color-darker: #707070;

  --bs-01-border-color-dark: #c0c0c0;
  --bs-01-border-color-darker: #707070;

  --bs-02-border-color-dark: #c0c0c0;
  --bs-02-border-color-darker: #707070;

  --bs-background-color-lighter: #fbfbfc;
  --bs-01-background-color-lighter: #fbfbfc;
  --bs-02-background-color-lighter: #fbfbfc;

  --bs-01-border-color-dark: #c0c0c0;
  --bs-01-border-color-darker: #707070;

  --bs-02-border-color-dark: #c0c0c0;
  --bs-02-border-color-darker: #707070;

  // Icons
  --bs-01-icon-padding: 0.6em;
  --bs-02-icon-padding: 0.6em;

  --bs-dark: #343a40;

  --bs-background-light: #f0f0f0;
  --bs-background-dark: #363b39;

  --bs-text-panel-title: 1.2em;
  --bs-text-panel-subtitle: 1em;

  --bs-text-primary: #fff;
  --bs-text-inverted-primary: #000;
}

// custom style reboot
html, body {
  //background-color: #ffffff;
  height: 100%;
  //color: $dc-color-primary;
}



//  ------------------  Generic formatting
.byc-01-primary {
  background-color: var(--bs-01-color-primary);
  color: var(--bs-01-text-color-primary);
}

.byc-02-primary {
  background-color: var(--bs-02-color-primary);
  color: var(--bs-02-text-color-primary);
}


//  --------------------------------------------------------------
//  ------------------  Text formatting

.byc-01-text-small {
  font-weight: var(--bs-01-text-weight-subtitle);
  font-size: var(bs-01-text-size-subtitle);
  font-size: var(--bs-01-text-size-small);
  color: var(--bs-01-color-dark);
}

.byc-02-text-small {
  font-weight: var(--bs-02-text-weight-subtitle);
  font-size: var(bs-02-text-size-subtitle);
  font-size: var(--bs-02-text-size-small);
  color: var(--bs-02-color-dark);
}

.byc-01-text-tiny {
  font-weight: var(--bs-01-text-weight-subtitle);
  font-size: var(bs-01-text-size-subtitle);
  font-size: var(--bs-01-text-size-tiny);
  color: var(--bs-01-color-dark);
}

.byc-02-text-tiny {
  font-weight: var(--bs-02-text-weight-subtitle);
  font-size: var(bs-02-text-size-subtitle);
  font-size: var(--bs-02-text-size-tiny);
  color: var(--bs-02-color-dark);
}

.byc-01-text-bold {
  font-weight: var(--bs-01-text-weight-bold);
}

.byc-02-text-bold {
  font-weight: var(--bs-01-text-weight-bold);
}


.byc-01-text-title {
  font-weight: var(--bs-01-text-weight-title);
  font-size: var(--bs-01-text-size-title);
  line-height: 1.3em;
}


.byc-02-text-title {
  font-weight: var(--bs-02-text-weight-title);
  font-size: var(--bs-02-text-size-title);
  line-height: 1.3em;
}


//  ------------------  Forms and Fields
.byc-01-form {
  width: 100%;
  padding: 1em;
}

.byc-02-form {
  width: 100%;
  padding: 1em;
}

.byc-01-form-field {
  width: 100%;
}

.byc-02-form-field {
  width: 100%;
}

.byc-01-form-field .mat-form-field-flex {
  border: 1px solid var(--bs-01-background-color-light);
}

.byc-02-form-field .mat-form-field-flex {
  border: 1px solid var(--bs-02-background-color-light);
}

//  ------------------  Containers
.byc-01-main-panel {
  border: 1px solid var(--bs-01-border-color-dark);
  border-radius: 0.5em;
  height: 100%;
}

.byc-02-main-panel {
  border: 1px solid var(--bs-02-border-color-dark);
  border-radius: 0.5em;
  height: 100%;
}


// Announcement
.byc-01-announcement-panel{
  border: 1px solid var(--bs-02-border-color-dark);
  border-radius: 0.5em;
  height: 100%;
  overflow: hidden;
}

.byc-01-announcement-main-heading{
  font-size: var(--bs-01-text-size-normal);
  font-weight: var(--bs-01-text-weight-heavy);
  padding-top:0.5em;
}

.byc-01-announcement-sub-heading{
  font-size: var(--bs-01-text-size-normal);
  font-weight: var(--bs-01-text-weight-heavy);
  padding-top:0.5em;
}

.byc-01-announcement-body-content{
  font-size: var(--bs-01-text-size-normal);
  font-weight: var(--bs-01-text-weight-normal);
  padding-top:0.5em;
  padding-bottom:0.5em;
}

.byc-01-announcement-pub{
  font-size: var(--bs-01-text-size-title);
  font-weight: var(--bs-01-text-weight-heavy);
  padding-top:1em;
  padding-bottom:1em;
}



//  --------------------------------------------------------------
//  ------------------  Icons and Images
.byc-01-image-charity-logo {
  height: 128px;
}

.byc-02-image-charity-logo {
  height: 128px;
}


//  ------------------  Buttons
.byc-01-next {
  padding: 0.5em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  font-size: 1.5em;
  border-radius: 0.5em !important;
  margin-bottom: 0.5em !important;
  margin-left: auto !important;
  margin-right: auto !important;

  min-width: 80%;
}

.byc-02-next {
  padding: 0.5em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  font-size: 1.5em;
  border-radius: 0.5em !important;
  margin-bottom: 0.5em !important;
  margin-left: auto !important;
  margin-right: auto !important;

  min-width: 80%;
}

.byc-01-small {
  padding: 0.25em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  font-size: 1em;
  border-radius: 0.25em !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0.5em !important;
}


.byc-02-small {
  padding: 0.25em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  font-size: 1em;
  border-radius: 0.25em !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0.5em !important;
}


.byc-icon-white{
  color: white;
}

.byc-icon-black{
  color: black;
}

.byc-onhover-pointer:hover{
  cursor: pointer;
}

//  ------------------  Home page menu
#byc-sidemenu{
  font-weight: var(--bs-text-weight-sidemenu);
  border-radius: var(--bs-border-radius);
}


#byc-sidemenu:hover{
  background-color: var(--bs-color-primary);
  color:var(--bs-text-color-primary)
}


#byc-toolbar{
  background-color:var(--bs-color-primary);
  color:var(--bs-text-color-primary);
  border-bottom: 1px solid #adb5bd;

  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  top: 0; /* Sets the sticky toolbar to be on top */
  z-index: 999; /* Ensure that your app's content doesn't overlap the toolbar */
}

mat-sidenav {
  width: 280px;
  padding:1em;
}

.main-content {
  padding:20px;
}
.toolbar-spacer {
  flex: 1 1 auto;
}

.byc-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

//  --------------------------------------------------------------
//  ------------------  Radio/Option

.byc-radio-button-small {
  border: 1px solid var(--bs-01-border-color-dark);
  border-radius: var(--bs-01-border-radius);
  padding: 1em;
  margin-top: 0.5em;

  font-size: 1em;
  font-weight: 500;
}

.byc-radio-button-small .mat-radio-container {
  margin-right: 0;
  margin-left: auto;

  margin-top: 0;
  margin-bottom: auto;
}

.byc-radio-button-small .mat-radio-label-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.byc-radio-button-small-body {
  font-weight: 400;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.byc-check-button-small {
  border: 1px solid var(--bs-01-border-color-dark);
  border-radius: var(--bs-01-border-radius);
  padding: 1em;
  margin-top: 0.5em;

  font-size: 1em;
  font-weight: 500;
}

.byc-check-button-small .mat-list-text {
  font-size: var(--bs-01-text-size-small);
  font-weight: var(--bs-01-text-weight-subtitle);
}

.byc-check-button-hidden .mat-pseudo-checkbox {
  display: none !important;
}

//  --------------------------------------------------------------
//  ------------------  Date picker
.byc-datepicker-available-dates {
  font-weight: var(--bs-01-text-weight-bold);
  background: rgba(83, 83, 83, 0.1);
  border-radius: 100%;
}

//  --------------------------------------------------------------
//  ------------------  Alerts  - updated 2021-10-26: CJ
//  --------------------------------------------------------------
.byc-alert-container {
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  padding: 0.5em;
  border-radius: 0 0 0.3em 0.3em;
  display: flex;
  justify-content: center;
  min-height: 100px;
  overflow-y: hidden;
  transition-property: all;
  transition-duration: 1s;
  word-break: break-all;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  padding:0.5em
}

.byc-alert-inner-container{
  width: 100%;
  border: 1px solid rgb(184, 184, 184);
  border-radius: 0.25em;
}

.byc-01-alert-message {
  line-height: 1.5em;
  font-size: 1em;
  color: white;
  padding: 0.5em;
}

.byc-02-alert-message {
  line-height: 1.5em;
  font-size: 1em;
  color: white;
  padding: 0.5em;
}

.byc-alert-info{
  background-color: cornflowerblue;
}

.byc-alert-warn{
  background-color: #e85d04;
}

.byc-alert-err{
  background-color:crimson
}



//  ------------------  Form fields
.byc-01-form-field {
  width: 100%;
}

.byc-02-form-field {
  width: 100%;
}

.byc-01-form-field .mat-form-field-flex {
  border: 1px solid var(--bs-01-background-color-light);
  padding:0.5em;
  padding-top: 0;
}

.byc-02-form-field .mat-form-field-flex {
  border: 1px solid var(--bs-02-background-color-light);
  padding:0.5em;
  padding-top: 0;
}

@import "./spinner";
